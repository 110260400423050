<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Štítky - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
        <a-form :form="form" @submit="handleSubmit">
          <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>
          <a-form-item
            :validate-status="error('label') ? 'error' : ''"
            :help="error('label') || ''"
            label="Označení"
          >
            <a-input placeholder="Označení" v-decorator="['label', {rules: [{max: 25, message: 'Označení nemůže být delší než 25 znaků!'}, {required: true, message: 'Označení musí být vyplněno!'}]}]"/>
          </a-form-item>

          <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>
          <a-form-item
            label="Barva pozadí"
          >
            <a-radio-group name="radioGroup" v-decorator="['color', { initialValue: this.colors[0].hex, rules: [{ required: true, message: 'Barva pozadí musí být vyplněna!' }] }]">
              <a-radio :style="radioStyle" :key="index" :value="color.hex" v-for="(color, index) in colors">{{ color.name }} | {{ color.hex }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            label="Náhled"
          >
            <div :style="'line-height:1.7;float:left;background-color:' + (this.form.getFieldValue('color')) + ';color: #fff !important;' +
           'padding: 0 10px;font-size: 11px !important;margin-bottom: 5px;text-transform: uppercase;display: block;' +
            'border-radius: 4px;-webkit-border-radius: 4px;-moz-border-radius: 4px;z-index: 1;'">{{(this.form.getFieldValue('label'))}}</div>
          </a-form-item>
          <div class="form-actions">
            <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
          </div>
        </a-form>
      </div>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: {
    ActionTools, LanguageTab,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/sticker',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
    }
  },
  computed: {
    language: function() {
      return this.$store.getters['language/active']
    },
    colors: function() {
      return this.$store.getters['sticker/colors']
    },
  },
  methods: {
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('sticker/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        languages: this.item.languages
          .filter(x => (x.label !== undefined && x.label.trim().length > 0))
          .map(x => {
            return {
              lge_id: x.lge_id,
              label: x.label !== undefined && x.label.trim().length > 0 ? x.label : null,
            }
          }),
        color: this.form.getFieldValue('color'),
      }
    },
    handleChange(oldLanguage) {
      let foundObj = false
      const languages = {
        lge_id: oldLanguage,
        label: this.form.getFieldValue('label'),
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = languages
          foundObj = true
          return true
        }
      })
      if (!foundObj) {
        this.item.languages.push(languages)
      }
    },
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = {
          languages: this.item.languages.find(x => x.lge_id === newValue),
        }
        this.form.setFieldsValue({ label: foundObj.languages === undefined ? undefined : foundObj.languages.label })
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
</style>
